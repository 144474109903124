import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL_API;


const tokendata=()=>{
  return localStorage.getItem("token");
}

const headerdata=()=>{
  let token = tokendata();
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
}

export const Register = (fullname, email, password, working,country) => {
  const headers = headerdata();
  var data = {
    name: fullname,
    email: email,
    password: password,
    working: working,
    country: country,
  };

  return axios.post(baseUrl + "register", data, headers);
};

export const Sign = (email, password) => {
  const headers = headerdata();
  var data = {
    email: email,
    password: password,
  };
  return axios.post(baseUrl + "login", data, headers);
};

export const Profile = () => {
  const headers = headerdata();
  return axios.post(baseUrl + "profile", null, headers);
};

export const Logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("User");
  return true;
};

export const AddInitiative = (fullname, scorecard, situation) => {
  const headers = headerdata();

  var data = {
    initiative_name: fullname,
    scorecard_name: scorecard,
    situation: situation,
  };
  return axios.post(baseUrl + "setevaluation", data, headers);
};

export const UpdateInitiative = (id, fullname, scorecard, situation,optscore,execscore) => {
  const headers = headerdata();

  var data = {
    initiative_name: fullname,
    scorecard_name: scorecard,
    situation: situation,
    opt_score:optscore,
    execution_score:execscore
  };
  return axios.post(baseUrl + "setevaluation/" + id, data, headers);
};

export const GetInitiative = () => {
  const headers = headerdata();
  return axios.post(baseUrl + "getevaluation", null, headers);
};

export const GetInitiativeById = (id) => {
  const headers = headerdata();

  let str = "";
  if (id) {
    str = "getevaluation/" + id;
  }
  return axios.post(baseUrl + str, null, headers);
};

export const AdminGetInitiative = (fromdate=null,todate=null) => {
  const headers = headerdata();
  var data = {
    fromdate: fromdate,
    todate: todate,
  };
  return axios.post(baseUrl + "admin/getevaluation", data, headers);
};

export const AdminGetInitiativeById = (id) => {
  const headers = headerdata();
  let str = "";
  if (id) {
    str = "admin/getevaluation/" + id;
  }
  return axios.post(baseUrl + str, null, headers);
};

export const deleteRow = (id) => {
  const headers = headerdata();
  var data = { evaluation_id: id };
  return axios.post(baseUrl + "deleteRow", data, headers);
};


export const Forgotpassword = (email) => {
  const headers = headerdata();
  var data = {email: email};
  return axios.post(baseUrl + "forgot", data, headers);
};


export const setJson = (json,evaluation_id) => {
  const headers = headerdata();
  var data = {json: json,evaluation_id:evaluation_id};
  return axios.post(baseUrl + "setjson", data, headers);
};

export const getJson =  (evaluation_id) => {
  const headers = headerdata();
  return axios.get(baseUrl + "getjson/"+evaluation_id, null, headers);
};

export const getSteps =  (evaluation_id) => {
  const headers = headerdata();
  return axios.get(baseUrl + "getsteps/"+evaluation_id, null, headers);
};

export const newVersion =  (evaluation_id) => {
  const headers = headerdata();
  return axios.get(baseUrl + "dublicate/"+evaluation_id, null, headers);
};

export const checkAllForm =  (evaluation_id) => {
  const headers = headerdata();
  return axios.get(baseUrl + "checkformsubmited/"+evaluation_id, null, headers);
};
import React from "react";
import {Container} from "react-bootstrap";

export default function ContactUs() {
    return (
        <div>
            <div className="handled-rich">
                <Container>
                    <div className="row pb-4">
                        <div className="col-md-12 beforehand-text-about-us">
                            <h2 className="js--title text-start">Contact Us</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="row pb-4">
                                <div className="col-md-12 beforehand-text-about-us">
                                    <h3 className="text-start">Take one simple step to ensure your digital success</h3>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="row pb-5">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <img src="img/__Email.png" className="js--image" alt=""></img>
                                                </div>
                                                <div className="col-md-9 pt-3 beforehand-text-about-us">
                                                    <h3 className="js--contact-us-font">
                                                        <a href={`mailto:Hemal.Patel@TheTakeoffPoint.com`}>
                                                            Hemal.Patel@TheTakeoffPoint.com
                                                        </a>
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row pb-5">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <img src="img/__Telephone.png" className="js--image" alt=""></img>
                                                </div>
                                                <div className="col-md-9 pt-3 beforehand-text-about-us">
                                                    <h3 className="js--contact-us-font">
                                                        <a href="tel:+1-469-213-1692">1-469-213-1692</a>
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row pb-5">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <img src="../img/__Home.png" alt="" className="js--image"/>
                                                </div>
                                                <div className="col-md-9 beforehand-text-about-us">
                                                    <h3 className="js--contact-us-font js--contact-us-address">
                                                        <a href="https://goo.gl/maps/UhT3z38UanhFKbPu9" target="_blank">
                                                            9808 Sota Grande Dr <br/>Plano, TX 75025 <br/>USA
                                                        </a>
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="row">
                                <div className="js--image-padding">
                                    <img src="img/contact_us.png" alt=""></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    );
}
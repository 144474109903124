import React, {useState} from 'react'
import {Button, Container, Nav, Navbar, NavDropdown} from 'react-bootstrap'

export default function Header() {
    const [show, setShow] = useState(false);
    const showDropdown = (e) => {
        setShow(!show);
    }
    const hideDropdown = e => {
        setShow(false);
    }
    return (
        <Navbar bg="light" expand="lg" className="pt-0 pb-0">
            <Container>
                <Navbar.Brand href="/"><img src="img/logo.png"></img></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto">
                        <Nav.Link href="/">Home</Nav.Link>
                        <NavDropdown className="pr-2 py-lg-4 align-text-top" title="Solutions" id="basic-nav-dropdown"
                                     show={show}
                                     onMouseEnter={showDropdown}
                                     onMouseLeave={hideDropdown}
                                     onClick={showDropdown}
                        >
                            <Container>
                                <div className="row">
                                    <div className="col-md-12">
                                        <ul className="rich-out-header">
                                            <li>
                                                <div className="consulting-reach-header">
                                                    <h6>By Services</h6>
                                                    <p><a href="/services#financial-gating-solution">Financial Gating Solution</a></p>
                                                    <p><a href="/services#product-formulation">Product Formulation</a></p>
                                                    <p><a href="/services#marketing-messages">Marketing Messages</a></p>
                                                    <p><a href="/services#research">Research</a></p>
                                                    <p><a href="/services#user-experience">User experience</a></p>
                                                    <p><a href="/services#team-formulation">Team Formulation</a></p>
                                                    <p><a href="/services#true-agile">TrueAgile</a></p>
                                                    <p><a href="/services#corporate-training">Corporate Training</a></p>
                                                </div>
                                            </li>
                                            <li className="js--pain-point-li">
                                                <div className="consulting-reach-header">
                                                    <h6>By Pain Point</h6>
                                                    <p>
                                                        <a href="/pain-points#am-i-building-or-investing-in-the-right-product">
                                                            Am I building or investing in the right product?
                                                        </a>
                                                    </p>
                                                    <p>
                                                        <a href="/pain-points#how-can-i-reduce-complexity-and-improve-time-to-market">
                                                            How can I reduce complexity and improve time to market?
                                                        </a>
                                                    </p>
                                                    <p>
                                                        <a href="/pain-points#where-exactly-is-my-customer">
                                                            Where exactly is my customer?
                                                        </a>
                                                    </p>
                                                    <p>
                                                        <a href="/pain-points#which-new-product-will-make-me-the-market-leader">
                                                            Which new product will make me the market leader?
                                                        </a>
                                                    </p>
                                                    <p>
                                                        <a href="/pain-points#what-do-i-need-to-change-in-my-existing-products">
                                                            What do I need to change in my existing products?
                                                        </a>
                                                    </p>
                                                    <p>
                                                        <a href="/pain-points#why-is-my-marketing-not-working">
                                                            Why is my marketing not working?
                                                        </a>
                                                    </p>
                                                    <p>
                                                        <a href="/pain-points#how-to-reach-and-engage-my-customers">
                                                            How to reach and engage my customers?
                                                        </a>
                                                    </p>
                                                    <p>
                                                        <a href="/pain-points#what-kind-of-research-and-analysis-do-i-need">
                                                            What kind of research and analysis do I need?
                                                        </a>
                                                    </p>
                                                    <p>
                                                        <a href="/pain-points#how-do-i-ensure-adoption-and-growth-of-my-product">
                                                            How do I ensure adoption & growth of my product?
                                                        </a>
                                                    </p>
                                                    <p>
                                                        <a href="/pain-points#what-kind-of-team-do-i-need-to-execute-the-vision">
                                                            What kind of team do I need to execute the vision?
                                                        </a>
                                                    </p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="consulting-reach-header">
                                                    <h6>By Roles</h6>
                                                    <p><a href="/roles#founders-and-ceos">Founders & CEOs</a></p>
                                                    <p><a href="/roles#venture-capitalists">Venture Capitalists</a></p>
                                                    <p><a href="/roles#executive-leadership">Executive Leadership</a></p>
                                                    <p><a href="/roles#angle-investors">Angle Investors</a></p>
                                                    <p><a href="/roles#entrepreneurs">Entrepreneurs</a></p>
                                                    <p><a href="/roles#product-owners">Product Owners</a></p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </Container>
                        </NavDropdown>

                        {/*<Nav.Link href="/services">Services</Nav.Link>*/}
                        <Nav.Link href="/about-us">About Us</Nav.Link>
                        <Nav.Link href="/contact-us">Contact Us</Nav.Link>
                        <Nav.Link href="/signup"> <Button variant="primary sign-button">Sign Up</Button>{' '}</Nav.Link>
                        <Nav.Link href="/sign"> <Button variant="primary sign-button">Sign In</Button>{' '}</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}
